import React from 'react'
import { Helmet } from 'react-helmet'
import { isMobile } from 'react-device-detect'
import rapBattleOhGif from './assets/media/rap_battle_oh.gif'

const COLORS = {
  almostBlack: '#0C0C0C',
  darkMode: '#12161A',
  gold: '#F0CA92'
}

function getTwitterShareHref() {
  const text = encodeURI('gg no re')
  const url = encodeURI('https://dude.gg')

  return `https://twitter.com/intent/tweet?text=${text}&url=${url}`
}

export default class App extends React.Component {
  componentDidMount() {
    if (this.refs.rapBattleVideo) {
      setTimeout(() => {
        this.refs.rapBattleVideo.play()
        console.log(`ran settimeout play`)
      }, 3000)
    }
  }

  rapBattleMedia() {
    if (isMobile) {
      return (
        <div style={styles.iframeWrapper}>
          <div style={styles.gifContainer}>
            <img src={rapBattleOhGif} alt='dude, gg' />
          </div>
        </div>
      )
    }
    return (
      <div style={styles.iframeWrapper}>
        <div style={styles.iframeContainer}>
          <video ref='rapBattleVideo' style={styles.iframe} title='OHHHHHH' controls autoPlay loop playsInline>
            <source src='https://dude.gg/rap_battle_oh.mp4' type='video/mp4' />
          </video>
        </div>
      </div>
    )
  }

  render() {
    const scriptTags = [{ src: '/twtr.js' }]
    if (process.env.REACT_APP_ENV === 'prod') {
      scriptTags.push({ src: '/ga.js' }, { src: '/fb.js' })
    }

    return (
      <>
        <Helmet script={scriptTags} />
        <div style={styles.app}>
          <div style={styles.header}>dude.gg</div>
          <div style={styles.body}>
            {this.rapBattleMedia()}
            <div style={{ marginTop: 25 }}>gg no re</div>
            <div style={{ marginTop: 25 }}>
              <a
                className='twitter-share-button'
                data-size='large'
                href={getTwitterShareHref()}
                target='_blank'
                rel='noopener noreferrer'
              >
                Tweet
              </a>
            </div>
          </div>
          <div style={styles.footer}>
            <span>YOLO ALL IN © 2020</span>
          </div>
        </div>
      </>
    )
  }
}

const styles = {
  app: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    backgroundColor: COLORS.darkMode,
    fontFamily: 'Roboto Condensed'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 80,
    minWidth: '100%',
    backgroundColor: COLORS.almostBlack,
    color: COLORS.gold,
    fontSize: 42
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
    fontSize: 24,
    color: COLORS.gold,
    padding: 25
  },
  iframeWrapper: {
    width: '100%',
    maxWidth: 750,
    maxHeight: 'auto'
  },
  iframeContainer: {
    position: 'relative',
    overflow: 'hidden',
    paddingBottom: '75%',
    width: '100%'
  },
  iframe: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    border: 0
  },
  gifContainer: {
    position: 'relative',
    overflow: 'hidden',
    width: '100%'
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: 60,
    width: '100%',
    backgroundColor: COLORS.almostBlack,
    fontFamily: 'Ballinger Mono',
    fontSize: 12,
    color: COLORS.gold
  }
}
